<template>
  <div id="app">
    <mu-container>
      <mu-row>
        <mu-col span="12">
          <mu-avatar class="avatar" :size="64">
            <img alt="littlebear avatar" src="https://s.gravatar.com/avatar/e87de9d5388c6586748707f4588c7fcb?s=80">
          </mu-avatar>
        </mu-col>
      </mu-row>
      <h1 style="font-size: 48px;">小熊的小站</h1>
      <mu-row gutter>
        <mu-col span="12" sm="12" lg="6">
          <div class="grid-cell">
            <mu-card class="card">
              <mu-card-title title="小熊的文件分享" sub-title="Powered by Directory Lister"></mu-card-title>
              <mu-card-text>
                <p>小秘密</p>
                <p>悄悄的用</p>
                <p>不要告诉别人哟</p>
              </mu-card-text>
              <mu-card-actions>
                <mu-button color="primary" style="margin-bottom: 20px;" href="https://bearxiong.xyz/file">前往下载</mu-button>
              </mu-card-actions>
            </mu-card>
          </div>
        </mu-col>
        <mu-col span="12" sm="12" lg="6">
          <div class="grid-cell">
            <mu-card class="card">
              <mu-card-title title="小熊的博客" sub-title="Try my best."></mu-card-title>
              <mu-card-text>
                <p>梦想</p>
                <p>是那么的尽在咫尺</p>
                <p>又是那样遥不可及</p>
              </mu-card-text>
              <mu-card-actions>
                <mu-button color="primary" style="margin-bottom: 20px;" href="https://blog.bearxiong.xyz/">参观博客
                </mu-button>
              </mu-card-actions>
            </mu-card>
          </div>
        </mu-col>
      </mu-row>
      <mu-row style="margin-top: 80px;">
        <mu-col span="12">
          <footer>Made by <a href="https://t.me/littlebear0729">@LittleBear0729</a> with ❤</footer>
          <footer>© 2018-{{ year }}</footer>
        </mu-col>
      </mu-row>
    </mu-container>
  </div>
</template>

<script>
import Vue from 'vue'
import MuseUI from 'muse-ui'
import 'muse-ui/dist/muse-ui.css'
import 'typeface-roboto'

Vue.use(MuseUI);
export default {
  name: 'App',
  components: {},
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.avatar {
  box-shadow: 0 7px 10px 0 rgba(0, 0, 0, .19);
}

.card {
  width: 90%;
  max-width: 375px;
  margin: 50px auto 0;
}

.card:hover {
  transition: .2s;
  transform: translate3d(0, +1px, 0);
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, .19);
}
</style>
